import $ from 'jquery'
import ScreenClickDrag from './screen_click_drag';
import { shuffle, trim } from 'lodash-es'
import { lesson_screen_drag_tbar, } from '@templates/student'

import { includeSoundEffects, includeVisualEffects } from '../common';
import { bindSelectors } from '@shared/utils';

// should we consider calculating this in case
// we have an excessively large number of items
const GAP_BETWEEN_ELEMENTS_PX = 10
const SELECTOR_ITEM = '.drag-tbar--item'

export default ScreenClickDrag.extend({

  lessonTemplate: lesson_screen_drag_tbar,

  sound: includeSoundEffects(),
  effects: includeVisualEffects(),

  selectors: {
    $submit: '.drag-tbar--submit',
    $workspace: '.drag-tbar',
    $side: '.drag-tbar--side',
    $drawer: '.drag-tbar--items',
    $items: SELECTOR_ITEM
  },

  events() {
    return {
      ...ScreenClickDrag.prototype.events,
      'click [remove-item]': 'onRemovePlacedItem',
      'click [submit-answer]': 'onSubmitAnswer'
    }
  },

  setup() {
    // read the lesson data
    this.lessonContent = this.parseLesson()
    bindSelectors(this)

    // tracking resolved shapes
    this.resolved = [ ]
  },

  build() {
    const { $workspace, $side, $items, $drawer } = this.selectors

    // determine origins for all draggable items
    const { items } = this.lessonContent
    const { itemSize } = applyLayout(items, $workspace, $drawer)

    // NOTE: .css just does nothing when setting a css variable??
    $workspace.attr('style', `--item-size: ${itemSize}px`)

    // container behaviors
    this.$el
      // handle dragging active items
      .on('pointermove', this.onUpdateItemPosition.bind(this))

    // setup each draggable item
    $items
      .on('dragstart', this.onBeginDragItem.bind(this))

      // set initial positions for each item
      .each((i, t) => {
        const el = this.$(t)
        const { x: left, y: top } = items[i]
        el.css({ left, top })
      })


    // handle dropping on a side
    const resolveItem = this.onResolveItem.bind(this)
    $side.on('pointerup', resolveItem)
    $workspace.on('pointerup', resolveItem)

    // handle releasing items at any time
    this.$(window).on('pointerup', resolveItem)
  },

  updateSubmitButton() {
    const { $submit } = this.selectors
    const counts = this.getCounts()
    const total = counts.left + counts.right
    const any = total > 0
    $submit.toggleClass('disabled', !any)
  },

  getItemFromEvent() {
    const { items } = this.lessonContent
    const index = 0 | this.target.data('index')
    return items[index]
  },

  // count the selected items
  getCounts() {
    const counts = {
      a: 0,
      b: 0,
      c: 0,
      d: 0,
    }

    this.$el.find('.done').each((i, el) => {
      const side = this.$(el).data('zone')
      counts[side]++
    })

    return counts
  },

  onSubmitAnswer() {
    const { $submit } = this.selectors
    const counts = this.getCounts()

    // require something to be added
    const total = Object.values(counts).reduce((a, b) => a + b, 0)
    if (total === 0) {
      // maybe needs to be more clear
      this.sound.play('error')
      return
    }

    // hide the submission button
    $submit.hide()

    // save the data
    this.screen.set('counts', counts)
    this.completeScreen()
  },

  onRemovePlacedItem(event) {
    const target = this.$(event.target).closest('[data-zone]')
    this.sound.play('error')

    if (this.shouldCountItems) {
      target.remove()
      this.updateSubmitButton()
    }
    else {
      this.revertTarget(target)
    }
  },

  onBeginDragItem(event) {
    event.stopPropagation?.()
    event.preventDefault?.()

    // revert the target, if any
    if (this.target) {
      this.revertTarget(this.target)
    }

    // get the element
    const $el = this.$(event.target).closest(SELECTOR_ITEM)

    // nothing to do
    if ($el.is('.done')) {
      return
    }

    // mark this as active
    this.target = $el
    $el.addClass('active')
  },

  onUpdateItemPosition(event) {
    if (this.target) {
      const position = this.getEventPosition(event)
      this.target.css({
        top: position.y,
        left: position.x
      })
    }
  },

  onResolveItem(event) {
    let { target, shouldCountItems } = this
    if (!target) {
      return
    }

    // get the side being dragged to
    const zone = this.$(event.target)
    const container = zone.closest('.drag-tbar--side')
    const side = container.data('zone')

    // always remove the active state
    target.removeClass('active')

    // it's a match
    if (target.data('zone') === side) {

      // successful placement
      this.effects.celebrate(target)
      this.sound.play('pop')

      // if this is a counting lesson, clone and revert
      if (shouldCountItems) {
        const clone = target.clone()
        clone.addClass('done')
        this.revertTarget(target)
        container.append(clone)

        // replace the target
        target = clone

        // clean up the area to make sure shapes aren't
        // overlapping on each other. Wait a moment for
        // everything to settle before updating
        setTimeout(() => makeRoom(container))

        // refresh the submission button
        this.updateSubmitButton()
      }
      // lock the position
      else {
        target.addClass('done')
      }

      // when correct, make sure the lesson is not done
      this.checkForLessonComplete()
    }
    // not a match
    else {
      this.revertTarget(target)

      // notify this was wrong
      this.sound.play('error')
    }

    // set as no longer active
    this.target = null
  },

  // return to the original position
  revertTarget(target) {
    const { lessonContent: { items } } = this
    const index = 0 | target.data('index')
    const { x: left, y: top } = items[index]
    target.css({ left, top })
      .removeClass('active')
      .removeClass('done')
  },

  checkForLessonComplete() {
    const { selectors: { $workspace }, shouldCountItems } = this
    if (shouldCountItems) {
      return false
    }

    // when finished, check if the lesson is done
    const done = $workspace.find('.done').length
    if (done === this.lessonContent.items.length) {
      this.completeScreen()
    }
  },

  // finds an event position relative to the workspace
  getEventPosition(event) {
    const { $workspace } = this.selectors
    const { top, left } = $workspace.offset()
    let { pageX: x, pageY: y } = event
    x -= left
    y -= top
    return { x, y }
  },

  serialize() {
    const data = ScreenClickDrag.prototype.serialize.call(this)
    const { zones, items, behavior } = this.lessonContent
    return { ...data, zones, items, behavior }
  },

  parseLesson() {
    const content = this.screen.get('content')

    // draggable items
    let items = [ ]
    const zones = { }

    // read the incoming content
    content.split('\n')
      .forEach((line, i) => {
        const zone = ['a', 'b', 'c', 'd'][i]
        const [category, imgs] = line.split(':')

        // save the zone name
        const name = trim(category)
        zones[zone] = { zone, name }

        // create each item
        imgs.split(/,/g)
          .map(trim)
          .filter(item => !!item)
          .forEach(img => {
            items.push({ img, zone })
          })
        })

    // quick shuffle for items
    items = shuffle(items)

    // if there's only two items, then assume this is a
    // counting lesson. If we need to support a two item
    // t-bar, this will need to be refactored
    const singleItemPerZone = items.length === Object.keys(zones).length
    const behavior = singleItemPerZone ? 'count' : 'place'
    this.shouldCountItems = behavior === 'count'

    return { zones, items, behavior }
  }

})

// applies initial layouts for items
function applyLayout(items, $tbar, $drawer) {
  const MIN_HEIGHT = 100
  const TOP = 20

  // center of the entire view
  const center = $tbar.width() >> 1

  // determine how much space is required
  let pair = false
  const availableHeight = $drawer.height()
  const requiredHeight = items.length * MIN_HEIGHT

  // if too many items, let them be doubled up
  if (requiredHeight > availableHeight) {
    $tbar.addClass('wide')
    pair = true
  }

  // sizing - these values are defined in the CSS file for _tbar.scss
  // maybe we can just assign them directly to the element
  const itemSize = pair ? 110 : 75
  const GAP_X = pair ? 20 : 0
  const GAP_Y = pair ? 20 : 15

  // update positions for each item
  for (let i = 0; i < items.length; i++) {
    const item = items[i]

    if (pair) {
      item.x = center + ((i % 2 === 0) ? -1 : 1) * ((itemSize + GAP_X) >> 1)
      item.y = TOP + (itemSize >> 1)  + (Math.floor(i / 2) * (itemSize + GAP_Y))

      // single row
    } else {
      item.x = center
      item.y = TOP + (itemSize >> 1)  + (i * (itemSize + GAP_Y))
    }
  }

  // give back info about the layout
  return { itemSize }
}

function makeRoom(container) {
  const items = container.find('.drag-tbar--item')
    .toArray()
    .map(el => {
      const item = $(el)
      const x = parseInt(el.style.left?.replace(/[^\d.]/g, ''), 10)
      const y = parseInt(el.style.top?.replace(/[^\d.]/g, ''), 10)
      return { item, x, y }
    })

  // hard coded for now. it's possible we might want to calculate the
  // size of the objects, but for now this should be sufficient space
  const size = 50

  // next, start looping through and making sure everything
  // is spread out enough
  let start = 0
  while (start < items.length) {
    const near = []

    // get the base items to compare
    const item = items[start]

    // gather all items that share the space
    for (let i = start + 1; i < items.length; i++) {
      const check = items[i];
      const dist = Math.hypot(check.y - item.y, check.x - item.x);
      if (dist < size) {
        near.push(check)
      }
    }

    // for all items that are too nearby, go ahead and push them away
    // from the origin point
    if (near.length > 0) {
      const ox = item.x;
      const oy = item.y;

      // add the base item
      near.push(item);

      // using a 360 sweep, push away from the origin
      const tau = Math.PI * 2
      const offset = Math.random() * Math.PI
      const step = tau / near.length
      const distance = size * 0.66
      for (let i = 0; i < near.length; i++) {
        const shift = near[i]
        const dir = offset + (step * i)
        shift.x = ox + Math.cos(dir) * distance
        shift.y = oy + Math.sin(dir) * distance
      }

      // restart
      start = 0
      continue
    }

    // move to the next item
    start++
  }

  // finally, update all positions
  for (const item of items) {
    item.item.css('left', item.x)
    item.item.css('top', item.y)
  }
}
