import moment from 'moment'
import { isArray, extend, cloneDeep } from 'lodash-es'
import Chart from 'chart.js'

export default {
  /**
   * Generates an object that can be passed into chartjs to build a beautiful chart
   * @param chartDefaults - object containing default chart options
   * @param type - line, bar, etc
   * @param labels - array of labels to be displayed on the X axis (dates need to be passed as UNIX TIMESTAMPS)
   * @param datasets - Object or array of objects representing all the Y axis datapoints
   * @param options - Object representing all options for the graph
   */
  createGraphObj: function(chartDefaults, type, labels, datasets, options) {
    type = type || 'line';
    var graphObj = cloneDeep(chartDefaults[type]); // A deep copy is needed to get all the correct default values

    if(!isArray(datasets)) { // Force our dataset to be an array of objects so we can loop through the datasets (even with just one dataset)
      datasets = [datasets];
    }

    var dataObj = graphObj.dataObj,
      datasetType = datasets[0].label,
      dateLabelFormat = (labels.length > 10 ? 'l' : 'll'); // Displays a shorter date if there are too many labels

    // This allows us to put short dates as the YAxes labels while retaining date/time as the tooltip
    dataObj.data.labels = labels.map((row) => Number.isInteger(row) ? moment(new Date(row)).format(dateLabelFormat) : row)
    dataObj.data.datasets = datasets.map((data) => {
      // Combine the graph's default dataset object with the graph's dataset options, with the data passed in by the user.
      return extend({}, graphObj.dataset, chartDefaults.getDatasetOptions(type), data);
    })

    dataObj.options = extend({}, dataObj.options, options);

    // Always have yAxes scaleLabel in order to display default yAxes labels
    if(!dataObj.options.scales.yAxes) {
      dataObj.options.scales.yAxes = [{ scaleLabel: { display: true } }];
    } else if(!dataObj.options.scales.yAxes[0].scaleLabel) {
      dataObj.options.scales.yAxes[0].scaleLabel = { display: true };
    }

    // Ensures the yAxes numbers have commas between thousands
    if(!dataObj.options.scales.yAxes[0].ticks) {
      dataObj.options.scales.yAxes[0].ticks = {};
    }
    if(datasetType === 'time') {
      dataObj.options.scales.yAxes[0].ticks.callback = function(label) { return parseInt(label).countdownSeconds(false); };
      dataObj.options.plugins.datalabels.formatter = function(value, context) {
        return parseInt(context.chart.data.datasets[0].data[context.dataIndex]).countdownSeconds(false);
      };
    } else {
      dataObj.options.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toLocaleString() + (datasetType === 'progress' || datasetType === 'accuracy' ? '%' : '');
      };
      dataObj.options.plugins.datalabels.formatter = function(value, context) {
        return ('shared.chart.datapoint_label_' + datasetType).t({ value });
      };
    }

    // Ensure at least part of the dataset is visible on the chart
    //dataObj.options.scales.yAxes[0].ticks.min = dataObj.options.scales.yAxes[0].ticks.min || Math.max(0, smallestValue - 10);

    // Dynamically sets the bar graph label visibility and color
    if (type === 'bar') {
      dataObj.options.plugins.datalabels.display = function(context) {
        return context.dataset.data[context.dataIndex] > 8 && context.dataset.data.length < 10;
      };
      dataObj.options.scales.yAxes[0].ticks.beginAtZero = true;
    } else if(type === 'line') {
      // Only line graphs need a background color on labels
      dataObj.options.plugins.datalabels.backgroundColor = chartDefaults.getLabelColor();
    }

    // Set default label if one is not provided
    if(!(options && options.scales && options.scales.yAxes && options.scales.yAxes[0] && options.scales.yAxes[0].scaleLabel && options.scales.yAxes[0].scaleLabel.labelString)) {
      dataObj.options.scales.yAxes[0].scaleLabel.labelString = ('shared.chart.tooltip_label_' + datasets[0].label).t();
    }

    // Set default tooltip label if one is not provided
    if(!(options && options.tooltips && options.tooltips.callbacks && options.tooltips.callbacks.label)) {
      dataObj.options.tooltips.callbacks = {
        title: function (tooltipItems, data) { // This allows us to put short dates as the YAxes labels while retaining date/time as the tooltip
          return isNaN(labels[tooltipItems[0].index]) ? labels[tooltipItems[0].index] : moment(new Date(labels[tooltipItems[0].index])).format('llll');
        },
        label: function (tooltipItems, data) {
          const value = (datasetType === 'time' ? parseInt(tooltipItems.yLabel).countdownSeconds(false) : tooltipItems.yLabel.toLocaleString())
          return ('shared.chart.tooltip_label_' + data.datasets[tooltipItems.datasetIndex].label).t({ value });
        }
      };
    }

    // Set default fontFamily
    Chart.defaults.global.defaultFontFamily = chartDefaults.getFontFamily()

    return dataObj;
  }
}
