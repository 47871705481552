import $ from 'jquery'
import { extend, sample } from 'lodash-es'
import { isIOS } from '@shared/helpers'

let dictationDetailsTimeout = 0;
let speechSynth = window.speechSynthesis || null;

const keyMap = {
  '!': 'exclamation mark',
  '@': 'at sign',
  '#': 'hash',
  '$': 'dollar sign',
  '%': 'percent',
  '^': 'carat',
  '&': 'ampersand',
  '*': 'asterisk',
  '(': 'open parentheses',
  ')': 'closed parentheses',
  '-': 'minus sign',
  '_': 'underscore',
  '+': 'plus sign',
  '=': 'equals sign',
  '/': 'slash',
  '{': 'open curly brace',
  '}': 'closed curly brace',
  '[': 'open square bracket',
  ']':' closed square bracket',
  '|': 'pipe symbol',
  '<': 'less than sign',
  '>': 'greater than sign',
  '?': 'question mark',
  '\\': 'back-slash',
  ' ': 'space',
  '\n': 'enter',
  '⏎': 'enter',
  ':': 'colon',
  ';': 'semicolon',
  '\'': 'apostrophe',
  '"': 'quotation mark',
  '~': 'tilde',
  '`': 'back-quote',
  ',': 'comma',
  '.': 'period'
}

// TODO - this method of finding special key movement is a hack!  Only works with EN US Keyboards.
const specialFingerMoves = {
  'g': 'one key to the right',
  'h': 'one key to the left',
  't': 'up and to the right one key',
  'y': 'up and to the left one key',
  'b': 'down and to the right one key',
  'n': 'down and to the left one key',
  '5': 'up two rows, and to the right one key',
  '%': 'up two rows, and to the right one key',
  '6': 'up two rows, and to the left one key',
  '^': 'up two rows, and to the left one key',
  '\'': 'one key to the right',
  '"': 'one key to the right'
};

const Dictation = function(screen, keyboard, user) {
  this.screen = screen;
  this.keyboard = keyboard;
  this.user = user;

  $(window).bind('beforeunload', function() {
    this.stop();
  }.bind(this));
};

var lastCallData,
  lastCallMethod;

const VIDEO_INTRO_QUICK_KEY_FREQUENCY = 15; // Define the frequency speaking quick key instructions

extend(Dictation.prototype, {

  setProperties: function(screen, keyboard) {
    this.screen = screen;
    this.keyboard = keyboard;
  },

  canDictate: function(data) {
    return data && typeof SpeechSynthesisUtterance === 'function' && speechSynth &&
      (FTWGLOBALS('language') === 'en' || FTWGLOBALS('language') === 'en-gb') && this.user.getSetting('dictation') &&
      (!this.user.get('in_class') || (this.user.get('in_class') && this.user.hasOption('allowdictation'))) &&
        (!this.screen || this.screen.get('dictation_type') !== 'none')
  },

  trackCall: function(data, method) {
    lastCallData = data;
    lastCallMethod = method;
  },

  speakIntro: function(data, showInstruction = true) {
    this.trackCall(data, 'speakIntro');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var title = this.formatTitle(data.title);

    var text = 'Welcome to ' + title;
    if(data.intro.trim()) {
      text += data.intro.trim().stripHTML();
    }

    // Append the instruction conditionally
    if(showInstruction){
      text += '. Press the enter key to continue.';
    }

    this.speak(text);
  },

  speakInstruction: function(data) {
    this.trackCall(data, 'speakInstruction')
    if(!this.canDictate(data)) { return; }

    this.stop()

    var title = this.formatTitle(data.title);

    var text = 'Welcome to ' + title + '! ';
    if(data.content.trim()) {
      text += data.content.trim().stripHTML();
    }

    this.speak(text)
  },

  speakQAIntro: function(data) {
    this.trackCall(data, 'speakQAIntro')
    if(!this.canDictate(data)) { return; }

    this.stop()

    let title = this.formatTitle(data.title)

    let text = 'Welcome to this digital literacy lesson. In this lesson you will learn about ' + title + '. After the lesson you will be asked a series of questions. Let\'s get started!'

    if(data.intro.trim() && data.intro.trim().stripHTML()) {
      text += data.intro.trim().stripHTML()
    }

    text += ' Press enter to begin the test.'

    this.speak(text)
  },

  speakTestIntro: function(data) {
    this.trackCall(data, 'speakTestIntro');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var title = this.formatTitle(data.title);

    var text = 'Welcome to the ' + title + '! Press enter to begin the test.';

    this.speak(text);
  },

  speakVideoIntro: function(data) {
    this.trackCall(data, 'speakVideoIntro');
    if(!this.canDictate(data)) { return; }

    this.stop();

    // Increment the counter or set it to 1 if it doesn't exist
    var counter = parseInt(localStorage.getItem('videoIntroCounter')) || 0;
    counter++;

    var title = this.formatTitle(data.title);
    var text = 'Welcome to ' + title + '!';
    var quickKeyText = ' To pause dictation, press the "d" key.';


    // Check if the video intro quick key instructions should be played based on the counter
    if (counter % VIDEO_INTRO_QUICK_KEY_FREQUENCY === 0) {
      text += quickKeyText += ' This lesson has an intro video. To play, press the "space bar" key. To skip the video, press Enter';

      // Reset the counter
      counter = 0;
    } else {
      text += ' This lesson has an intro video. To play, press the "space bar" key. To skip the video, press Enter';    }

    // Store the updated counter
    localStorage.setItem('videoIntroCounter', counter);

    this.speak(text);
  },

  speakVideoIntroEnding: function(data) {
    this.trackCall(data, 'speakVideoIntro');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = 'Press the enter key to continue.';

    this.speak(text);
  },

  speakScreen: function(data) {
    this.trackCall(data, 'speakScreen');
    if (!this.canDictate(data) ||
      (!data.clickDrag && !data.singleKeyComplete && !data.nextKey)) { return; }

    this.stop();

    // https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesis
    let speechDelay = 0,
      text = '',
      content = this.screen.get('screen_type') !== 'keyboard-jump'
        ? this.screen.get('content')
        : this.screen.get('formatted_content');

    // start a screen
    if (data.clickDrag) {
      if (data.mode === 'how_to') {
        text = 'Let\'s practice clicking and dragging. Click and drag the letter into the target below.'
      } else if (data.mode === 'intro') {
        text = 'Press the enter key to continue practicing clicking and dragging.'
      } else if (data.mode === 'how_to_confirm') {
        text = 'Wow, nicely done! Press the enter key to continue.'
      } else if (data.mode === 'lesson') {
        if (data.drop) {
          text = sample(['Great!', 'Wow!', 'Excellent!', 'Perfect!', '', ''])
        } else {
          text = 'Now, drag the items below into their proper place on the screen above.'
        }
      } else if (data.mode === 'complete') {
        text = 'Great work! Press the enter key to continue.'
      }
    } else if (data.singleKeyComplete) {
      text = 'Nice work! Press the enter key to continue.'
    } else if(this.screen.get('screen_type') === 'written-prompt') {
      text = 'A Creative writing lesson. In this lesson you will need to freely type a ' + this.screen.get('settings')?.word_count + ' word response to the following question ,,, ' + content + '. ,,,, Start typing to begin.'
    } else if(data.begin) {
      if(this.screen.get('new_key') && data.mode === 'key_confirm') {
        text = 'You found it!  Remember where that ' + this.getKeyName(this.screen.get('new_key')) + ' key is, because we\'re going to practice it now!  Press Enter to begin.';
      } else if (this.screen.get('new_key') && !data.mode) {
        text = 'It\'s time to learn the ' + this.getKeyName(this.screen.get('new_key')) + ' key. ' + this.getFingerDetails(this.screen.get('new_key'), false) + ' to type the ' + this.getKeyName(this.screen.get('new_key')) + ' key.';
      } else {
        if (this.screen.get('screen_type') === 'keyboard-jump') {
          text = 'A Typing game is about to begin. Type the ' + this.screen.get('dictation_type') + ' as they appear. ';
        }

        if(this.screen.get('dictation_type') === 'letters' || this.getWordAt(content, 0).length === 1) {
          if(this.isLetter(data.nextKey)) {
            text += 'To begin, type the letter: ' + this.getKeyName(data.nextKey) + '.';
          } else if(this.isNumber(data.nextKey)) {
            text += 'To begin, type the number: ' + data.nextKey + '.';
          } else {
            text += 'To begin, type the ' + this.getKeyName(data.nextKey) + ' key.';
          }
        } else if(this.screen.get('dictation_type') === 'words') {
          text += 'To begin, type the word ' + this.formatWord(this.getWordAt(content, 0)) + '. ' + this.getKeyName(data.nextKey) + '.';
        }
      }
      // if they typed incorrectly
    } else if(data.isError) {
      text = 'Oops, you typed ' + this.getKeyName(data.letterTyped);

      if(data.lastKeyError) {
        text += (', type the ' + this.getKeyName(data.nextKey) + ' key');
      }
      // if they typed correctly
    } else {
      if(this.screen.get('dictation_type') === 'letters' || this.getWordAt(content, data.typed).length === 1) {
        text = this.getKeyName(data.nextKey) + '.';
      } else if(this.screen.get('dictation_type') === 'words') {
        if(data.nextWord) {
          text = data.nextWord + '.';
        } else if(data.nextKey !== ' ' && data.correctLetter === ' ') {
          text = this.formatWord(this.getWordAt(content, data.typed))  + '. "' + this.getKeyName(data.nextKey) + '"';
        } else if (this.isSymbol(data.nextKey)) {
          text = this.getKeyName(data.nextKey) + '.';
        } else {
          text = this.getKeyName(data.nextKey) + '.';
        }
      }
      speechDelay = 300;
    }

    if(!(this.screen.get('new_key') && data.begin) && !data.clickDrag && !data.singleKeyComplete && this.screen.get('screen_type') !== 'written-prompt') {
      text += (this.getFingerDetails(data.nextKey, true) + ' to type the ' + this.getKeyName(data.nextKey) + ' key.');
    }

    if(FTWGLOBALS('env') === 'local') {
      console.log('Dictation contents: ' + text);
    }

    window.clearTimeout(dictationDetailsTimeout); // Kill the previous speaking text or else they start stacking
    dictationDetailsTimeout = window.setTimeout(() => {
      this.speak(text);
    }, speechDelay);
  },

  speakWrittenPrompt: function(data) {
    this.trackCall(data, 'speakWrittenPrompt');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = 'This written prompt is titled ' + data.title + '. You must respond to the following prompt by writing ' + data.word_count + ' words,, ' + data.description + '. Hit enter to submit your response.';

    this.speak(text);
  },

  speakScreenComplete: function(data) {
    this.trackCall(data, 'speakScreenComplete');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = ''

    if(this.screen.get('screen_type') !== 'written-prompt') {
      text = 'Congratulations! You have completed this screen, and earned ' + data.stars + ' star' + data.stars.pluralize() + '!';
      text += ' You spent ' + this.secondsToWords(data.seconds) +', and your speed averaged ' + data.speed + ' words per minute with ' + data.accuracy + '% accuracy.';
    } else {
      text = 'Congratulations! You have completed this screen.'
    }
    text += ' Press the enter key to continue.';


    this.speak(text);
  },

  speakLoadingAIContent: function() {
    this.trackCall({}, 'speakLoadingAIContent');
    if(!this.canDictate({})) { return; }

    this.stop();

    this.speak('Hold on, we’re creating your story');
  },

  speakOptionPaths: function(data) {
    this.trackCall(data, 'speakOptionPaths')
    if(!this.canDictate(data)) { return }

    this.stop()

    let text = ''

    if(data.options.length <= 1) {
      text = 'Press the enter key to continue'
    } else {
      text = 'What will you do next? Choose by pressing the corresponding number key. ';
      data.options.forEach((answer, index) => {
        text = text + (index + 1) + '... ' + answer + '... ';
      });

      text += ',,,, Press zero to repeat the options.';
    }

    this.speak(text);
  },

  speakOption: function(data) {
    this.trackCall(data, 'speakOption');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = data.text += ',,,, Press Enter to submit your answer.';

    this.speak(text);
  },

  speakTYOAScreenWithEndingComplete: function(data) {
    this.trackCall(data, 'speakTYOAScreenWithEndingComplete')
    if(!this.canDictate(data)) { return }

    this.stop()

    let text = 'The end. You made all the right choices today, great job!,,,, Press Enter to continue.'

    this.speak(text)
  },

  speakEmailPaths: function(data) {
    this.trackCall(data, 'speakEmailPaths')
    if(!this.canDictate(data)) { return }

    this.stop()

    let text = 'You received an email response! ' + (data.pickFigure ? '' : data.response)

    if(data.options.length <= 1) {
      text += 'Press the enter key to continue'
    } else {
      text += (data.pickFigure ? 'Who would you like to message?' : 'What will you do next?') + 'Choose by pressing the corresponding number key. ';
      data.options.forEach((answer, index) => {
        text = text + (index + 1) + '... ' + answer + '... ';
      });

      text += ',,,, Press zero to repeat the options.';
    }

    this.speak(text);
  },

  speakLessonComplete: function(data) {
    this.trackCall(data, 'speakLessonComplete');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = 'Congratulations, you have completed this entire lesson!';

    if (!data.hideStars) {
      text += ' You earned ' + data.stars + ' out of ' + data.totalStars + ' stars.'
    }

    if (!data.hideTypingStats) {
      text += ' You spent ' + this.secondsToWords(data.seconds) +', and your speed averaged ' + data.speed + ' word' + data.speed.pluralize() + ' per minute with ' + data.accuracy + '% accuracy.';
    }

    this.speak(text);
  },

  speakQALessonComplete: function(data) {
    this.trackCall(data, 'speakQALessonComplete');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = 'Congratulations, you have completed the "' + data.lesson.name + '" quiz. You answered ' + data.numCorrectAnswers + ' out of ' + data.lesson.screens + ' question' + data.lesson.screens.pluralize() + ' correctly.';

    this.speak(text);
  },

  speakQAQuestion: function(data) {
    this.trackCall(data, 'speakQAQuestion');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = '';
    data.question = data.question.replace('_', ' ...blank... ');

    if(data.questionIndex === 1) {
      text = 'This quiz contains ' + data.questionCount + ' questions. Answer questions by pressing the corresponding number key. Press zero to repeat the question... To begin, answer the following question: ' + data.question + '. ';
    } else if(data.questionIndex === data.questionCount) {
      text = 'Last question. ' + data.question + '. Is it... ';
    } else {
      text = 'Question ' + data.questionIndex + ' of ' + data.questionCount + '. ' + data.question + '. Is it... ';
    }

    data.answers.forEach(function(answer, index) {
      text = text + (index + 1) + '... ' + answer + '... ';
    });

    text += ',,,, Press zero to repeat the question.';

    this.speak(text);
  },

  speakConceptMapOverviewInstructions: function (data) {
    this.trackCall(data, 'speakConceptMapOverviewInstructions');
    if(!this.canDictate(data)) { return; }

    this.stop();

    const message = ['This is a concept map lesson.']

    // ready to submit
    if (data.submit) {
      message.push('You have finished all nodes. Press submit to finish this lesson.')
    }
    else if (data.node) {
      message.push(`You must complete each node in order. Click on the node labeled ${data.node} to continue the lesson.`)
    } else {
      message.push(`You must complete all nodes in order to finish. Click on any unfinished node to begin the task.`)
    }

    const text = message.join('\n')

    this.speak(text);
  },

  speakQAAnswer: function(data) {
    this.trackCall(data, 'speakQAAnswer');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = data.text += ',,,, Press Enter to submit your answer.';

    this.speak(text);
  },

  speakTimeRemaining: function(data) {
    this.trackCall(data, 'speakQAAnswer');
    if(!this.canDictate(data)) { return; }
    this.stop();

    var text = this.secondsToWords(data.text) + ' remaining.';

    this.speak(text);
  },

  speakTestComplete: function(data) {
    this.trackCall(data, 'speakTestComplete');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = 'Congratulations, you have completed this typing test!';
    text += ' You spent ' + this.secondsToWords(data.seconds) +', and your speed averaged ' + data.speed + ' word' + data.speed.pluralize() + ' per minute with ' + data.accuracy + '% accuracy.';

    this.speak(text);
  },

  speakVoiceTest: function(testVoice) {
    const voice = this.getVoiceByUri(testVoice),
      say = new SpeechSynthesisUtterance('Hi, this is ' + voice.name);

    say.pitch = 1
    say.rate = 1
    say.voice = voice
    speechSynth.cancel();

    return speechSynth.speak(say);
  },

  speakFailed: function(data) {
    this.trackCall(data, 'speakFailed');
    if(!this.canDictate(data)) { return; }

    this.stop();

    var text = 'Oh no, you missed the benchmark!';
    // Speak something different if the user fails to finish an inline game
    if((this.screen.get('screen_type') === 'keyboard-jump') &&
      (this.screen.get('typed') < this.screen.get('content').length)) {
      text += ' To complete this screen you must type all of the screen content in the game.';
    } else {
      text += ' You\'ve completed this screen with ';
      if(this.screen.get('min_speed') > data.speed){ text += (data.speed + ' word' + parseInt(data.speed).pluralize() + ' per minute '); }
      if(this.screen.get('min_speed') > data.speed && this.screen.get('min_acc') > data.accuracy){ text += ' and '; }
      if(this.screen.get('min_acc') > data.accuracy){ text += (data.accuracy + '% accuracy.'); }
      text += '. ';

      text += ' To move on, you must complete the screen with a minimum of ';
      if(this.screen.get('min_speed') > data.speed){ text += (this.screen.get('min_speed') + ' word' + parseInt(this.screen.get('min_speed')).pluralize() + ' per minute '); }
      if(this.screen.get('min_speed') > data.speed && this.screen.get('min_acc') > data.accuracy){ text += ' and '; }
      if(this.screen.get('min_acc') > data.accuracy){ text += (this.screen.get('min_acc') + '% accuracy.'); }
      text += '. ';
    }

    text += ' Press the enter key to try again.';

    this.speak(text);
  },

  speak: function(text) {
    if(this.dictationResume) { // Tell the user when turning dictation on
      text = 'Dictation is on... ' + text;
      this.dictationResume = false;
    }

    var say = new SpeechSynthesisUtterance(text);
    say.pitch = 1
    say.rate = 1
    // say.voice = this.getVoiceByUri(this.user.getSetting('dictation_voice'))
    speechSynth.cancel();
    return speechSynth.speak(say);
  },

  stop: function() {
    if(speechSynth?.speaking) {
      speechSynth.pause();
    }
  },

  resume: function() {
    if(lastCallData && lastCallMethod) {
      this.dictationResume = true;
      // we store the last call and it's data so if they don't have dictation going, it'll begin when they start
      this[lastCallMethod].call(this, lastCallData, lastCallMethod);
    } else {
      this.speak('Dictation is on.');
    }
  },

  turnOff: function() {
    if(speechSynth) {
      speechSynth.cancel();

      var say = new SpeechSynthesisUtterance();
      say.text = 'Dictation is off';
      say.onend = function() {
        speechSynth.cancel();
      };
      speechSynth.speak(say);
    }
  },

  getFingerDetails: function(key, addPause) {
    if(!this.keyboard || key === null) { return ''; }

    var finger = this.keyboard.getFingerFor(key),
      row = this.keyboard.getRowFor(key),
      shifted = this.keyboard.getKeyRequiresShift(key),
      dead = this.keyboard.getKeyRequiresDead(key),
      hand = (finger <= 5) ? 'left' : 'right',
      text = ' ',
      fingerText;

    if(dead) {
      if(dead.modifier === 'alt') {
        finger = (hand === 'right') ? 1 : 10;
        row = 4;
      }

      text = 'First ';
    }


    switch(finger) {
    case 1:
    case 10:
      fingerText = 'pinkie';
      break;
    case 2:
    case 9:
      fingerText = 'ring finger';
      break;
    case 3:
    case 8:
      fingerText = 'middle finger';
      break;
    case 4:
    case 7:
      fingerText = 'index finger';
      break;
    default:
      fingerText = 'thumb';
      hand = '';
    }

    if(shifted) {
      if(hand === 'left') {
        text += 'Hold the right shift, and ';
      } else {
        text += 'Hold the left shift, and ';
      }

    }

    if(specialFingerMoves[key]) {
      text += 'move your ' + hand + ' ' + fingerText + ' ' + specialFingerMoves[key];
    } else if(row === 0) { // number row
      text += 'move your ' + hand + ' ' + fingerText + ' up two rows';
    } else if (row === 1) {
      text += 'move your ' + hand + ' ' + fingerText + ' up';
    } else if(row === 2) {	// 2 is the home row
      text += 'use your ' + hand + ' ' + fingerText;
    } else if (row === 3) {
      text += 'move your ' + hand + ' ' + fingerText + ' down';
    } else if (row === 4) {
      if(dead && dead.modifier) {
        text += 'move your ' + hand + ' ' + fingerText + ' down two rows and hold the "' + dead.modifier + '" key, and ' + this.getFingerDetails(dead.deadKey) + ' to type the ' + '\"'+dead.deadKey+'\" key. Next, return your fingers to the home row.  Finally, ' + this.getFingerDetails(dead.key);
      } else {
        text += 'use your ' + hand + ' ' + fingerText;
      }

    }

    if(addPause) {
      text = ' ,,,, ' + text;
    }

    return text;
  },

  getWordAt: function(str, pos) {
    // Search for the word's beginning and end.
    var left = str.slice(0, pos + 1).search(/\S+$/),
      right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
      return str.slice(left);
    }

    // Return the word, using the located bounds to extract it from the string.
    return str.slice(left, right + pos);
  },

  formatWord: function(word) {
    if(Number(word) == word) {
      return word;
    } else {
      return '"' + word + '"';
    }

  },

  formatTitle: function(title) {
    if(title && title.match(/:/)) {
      // titles often begin with a number and colon.  remove that.
      var titleParts = title.split(/:/);
      titleParts.shift();
      title = titleParts.join('');
    }

    return title;
  },

  getKeyName: function(key) {
    var word = keyMap[key];
    if(word) { return word; }

    if(this.isNumber(key)) {
      return key;
    } else if(key === key.toUpperCase()) {
      return 'Upper case "' + key.toLowerCase() + '"';
    }

    if(isIOS()) {
      return key.toUpperCase() + ',';
    } else {
      return key.toUpperCase() + ',';
    }

  },

  secondsToWords: function(seconds) {
    seconds = Math.floor(seconds);
    var time = '';
    if(seconds <= 60) {
      time = seconds + ' second' + seconds.pluralize();
    } else {
      var minutes = Math.floor(seconds / 60),
        remainingSeconds = seconds - minutes * 60;
      time = minutes + ' minute' + minutes.pluralize() + ' ' + remainingSeconds + ' second' + remainingSeconds.pluralize();
    }

    return time;
  },

  isLetter: function(key) {
    return key.match(/[a-zA-Z]/);
  },

  isNumber: function(key) {
    return key !== ' ' && key == Number(key);
  },

  isSymbol: function(key) {
    return keyMap[key];
  },

  getVoices: function() {
    const voices = speechSynth.getVoices()

    return voices.filter((row) => row.lang.toLowerCase().startsWith('en-'))
  },

  getVoiceByUri: function(uri) {
    const voices = speechSynth.getVoices()

    return voices.find((row) => row.voiceURI === uri) || voices[0]
  }
});

export default Dictation
