import Backbone from 'backbone'
import $ from 'jquery'
import Registry from '@registry'
import LocalstorageBackend from '../../../../shared/js/model_backends/localstorage'
import { Cookies, isIE11 } from '@shared/helpers';
import { rot47 } from '@shared/helpers'
import { filter, isArray, map, isEmpty } from 'lodash-es';

export default Backbone.Model.extend({
  idAttribute: 'user_id',

  urlRoot: '/apiv1/student/user',

  defaults: {
    user_id: 0,
    teacher_id: null,
    section_id: null,
    district: {},
    options: null,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    last_login: 0,
    demo: false,
    speed: 'wpm',
    spaces: 1,
    skin_id: FTWGLOBALS('whitelabel') ? 10 : 1, // EduTec gets a default skin for anon users
    variant_id: 1,
    scoreboard: 1,
    settings: {
      language: FTWGLOBALS('defaultLanguage'),
      product_id: (() => {
        if(!window.__noLocalStorage && window.localStorage.getItem('student_product_id')) { return window.localStorage.getItem('student_product_id'); }
        return 'typing';
      })(),
      keyboard_id: 1,
      show_hands: 1,
      animated_hands: 1,
      show_keyboard: 1,
      typing_sounds: 1,
      error_sounds: 1,
      individual_org_type: '',
      dictation: 0,
      daily_goal: 15
    },
    // Underscore "_" prefix means the field is not in the database
    _mobileKeyboard: '',
  },

  /**
   * Update all the proper global models and collections with data from the server after login
   * @param {Object} data
   */
  loginWithData: function(data) {
    // Check to see if there was already a user session.
    var oldUser = JSON.parse(rot47(window.localStorage.getItem(rot47('student_student'))));

    if(oldUser && oldUser.user_id !== 0 && oldUser.user_id !== data.user_id) {
      data._prevUserSession = true;
    }

    this.clearLocalStorage();

    // We need to know if the student has manually set their goal so we can spam them if they haven't
    if(data.settings?.daily_goal) {
      data._dailyGoalSet = true
    }

    // Merge the default settings with the settings received from the user
    data.settings = { ...this.defaults.settings, ...data.settings }

    var lettersTyped = [],
      row;
    for (var i=97; i<=122; i++) {
      row = {id: String.fromCharCode(i), typed: 0, errors: 0};
      if (data.letters_typed[String.fromCharCode(i) + 'typed']) {
        row.typed = data.letters_typed[String.fromCharCode(i) + 'typed'];
      }
      if (data.letters_typed[String.fromCharCode(i) + 'errors']) {
        row.errors = data.letters_typed[String.fromCharCode(i) + 'errors'];
      }
      lettersTyped.push(row);
    }
    var userLettersTyped = Registry.get('userLettersTyped');
    userLettersTyped.reset(lettersTyped, {silent: true});
    userLettersTyped.setBackend(new LocalstorageBackend('userLettersTyped'), 'model');
    delete data.letters_typed;

    var customLessons = Registry.get('customLessons');
    customLessons.reset(data.custom, {silent: true});
    customLessons.setBackend(new LocalstorageBackend('customLessons'), 'model');
    delete data.custom;

    var userActivity = Registry.get('userActivity');
    userActivity.reset(window.__makeAssoc(data.activity, data.activity_fields), {silent: true});
    userActivity.setBackend(new LocalstorageBackend('userActivity'), 'model');
    delete data.activity;
    delete data.activity_fields;

    var userLessons = Registry.get('userLessons');
    userLessons.reset(window.__makeAssoc(data.lessons, data.lessons_fields), {silent: true});
    userLessons.setBackend(new LocalstorageBackend('userLessons'), 'model');
    delete data.lessons;
    delete data.lessons_fields;

    var userLessonScreens = Registry.get('userLessonScreens');
    userLessonScreens.reset(window.__makeAssoc(data.screens, data.screens_fields), {silent: true});
    userLessonScreens.setBackend(new LocalstorageBackend('userLessonScreens'), 'model');
    delete data.screens;
    delete data.screens_fields;

    // Written Responses can be partially finished. For each partially finished response, save it to local storage to be picked up by the screen
    if(data.responsesInProgress) {
      data.responsesInProgress.forEach((row) => {
        data['_writtenResponse' + row.lesson_screen_id] = row.response
      })
      delete data.responsesInProgress
    }

    var userAchievements = Registry.get('userAchievements');
    userAchievements.reset(data.achievements, {silent: true});
    userAchievements.setBackend(new LocalstorageBackend('userAchievements'), 'model');
    delete data.achievements;

    var userTests = Registry.get('userTests');
    if(data.tests && data.tests_fields) {
      userTests.reset(window.__makeAssoc(data.tests, data.tests_fields), {silent: true});
    } else {
      userTests.reset([], {silent: true});
    }
    userTests.setBackend(new LocalstorageBackend('userTests'), 'model');
    delete data.tests;
    delete data.tests_fields;

    var userGames = Registry.get('userGames');
    if(data.games && data.games_fields) {
      userGames.reset(window.__makeAssoc(data.games, data.games_fields), {silent: true});
    } else {
      userGames.reset([], {silent: true});
    }
    userGames.setBackend(new LocalstorageBackend('userGames'), 'model');
    delete data.games;
    delete data.games_fields;

    var assignments = Registry.get('assignments');
    assignments.reset(data.assignments, {silent: true});
    assignments.setBackend(new LocalstorageBackend('assignments'), 'model');
    delete data.assignments;

    // Used to fire a GA event ONE TIME in app.js
    data._loggingIn = true

    this.clear({silent: true});
    this.set(data, {silent: true});
    this.setBackend(new LocalstorageBackend('student'), 'model');

    const products = Registry.get('products');
    window.localStorage.setItem('student_product_id', data.settings.product_id || products.first().id);

    window.localStorage.setItem('student_skin', data.skin_id);

    if(this.get('licensed_at') > 0) {
      window.localStorage.setItem('student_license', true);  // blade layout will use this to load or not load google
    }

    if(data.adType) {
      localStorage.setItem('_adType', data.adType)
    } else {
      localStorage.removeItem('_adType')
    }

  },

  resyncLocal: function() {
    // Save the data to local storage

    $.get('/apiv1/student/auth/resync').done(function(data){
      Registry.get('userActivity').reset(window.__makeAssoc(data.activity, data.activity_fields));

      Registry.get('userLessons').reset(window.__makeAssoc(data.lessons, data.lessons_fields));

      Registry.get('userLessonScreens').reset(window.__makeAssoc(data.screens, data.screens_fields));

      if(data.tests && data.tests_fields) {
        Registry.get('userTests').reset(window.__makeAssoc(data.tests, data.tests_fields));
      } else {
        Registry.get('userTests').reset([]);
      }

      if(data.games && data.games_fields) {
        Registry.get('userGames').reset(window.__makeAssoc(data.games, data.games_fields));
      } else {
        Registry.get('userGames').reset([]);
      }

      Registry.get('assignments').reset(data.assignments);

    });

  },

  getSetting: function(name) {
    return this.get('settings')[name]
  },

  /**
   * Saves new user settings to their local model
   * @param newSettings object of settings to be set { keyboard_id: x }
   */
  setSettings: function(newSettings) {
    let settings = this.get('settings')

    this.set({ settings: { ...settings, ...newSettings } })

    if(newSettings.language) {
      Cookies.set('lang', newSettings.language, {path: '/', expires: 30})
    }
    if(newSettings.product_id) {
      window.localStorage.setItem('student_product_id', newSettings.product_id)
    }

    // Manually trigger the settings being changed so that other areas of the app know exaxtly which settings were updated
    this.trigger('settings_changed', Object.keys(newSettings))
  },

  hasOption: function(option) {
    let settings = this.get('settings'),
      options = settings?.options || {}

    if(option === 'changepassword' && this.get('must_change_password')) return true;
    if(option === 'lockaccount' && this.get('must_update_name')) return false;

    //   if(this.get('in_class') && this.typingForcedOptions[option] !== undefined) {
    //     return this.typingForcedOptions[option];
    //   } else if(!this.get('in_class')) {
    //     return this.individualForcedOptions[option];
    //   }

    if(isEmpty(options)) return true;  // options are enabled by default. If there are no options, it means

    if(isArray(options)) {  // old format was an array of strings
      var newOptions = {};
      options.forEach((option) => newOptions[option] = true)
      settings.options = options = newOptions
      this.set({ settings })
    }
    return options[option] !== false; // could be true or undefined. undefined if it's from the old format where it was an array of strings that were true instead of each option true/false, which now allows for adding new things
  },

  hasGame: function(gameId) {
    const settings = this.get('settings')
    if(!settings || !settings.games) return true

    return settings.games.indexOf(gameId) !== -1
  },

  getGames: function(games) {
    let gamesList = filter(games, (game) => this.hasGame(game.game_id))

    if(isIE11()) {
      gamesList = map(gamesList, (game) => {
        if(game.folder === 'ztype' || game.folder === 'tommyq' || game.folder === 'keyboard-jump') {
          game.display_order = 999
          game.failBrowser = true
        }
        return game
      });
    }

    return gamesList
  },

  refreshSession: function() {
    return $.get('/apiv1/student/auth/refresh-session');
  },

  /**
   * Log the user out
   * @param object [opts] ignoreServer
   * @returns {*}
   */
  logOut: function(opts) {
    //throw new error;
    opts = opts || {};

    this.clearLocalStorage();

    window.localStorage.setItem('_userLoggingOut', '1');
  },

  clearLocalStorage: function() {
    var len = window.localStorage.length,
      keys = [],
      key;

    Cookies.set('sso', '', {path:'/', expires: 1});
    Cookies.set('sso_id', '', {path:'/', expires: 1});
    Cookies.set('join_code', '', {path:'/', expires: 1});
    Cookies.set('sso_form', '', {path:'/', expires: 1});

    for (var i = 0; i < len ; i++){
      if(rot47(window.localStorage.key(i)).match(/^student/)) {
        keys.push(window.localStorage.key(i));
      }

      if(window.localStorage.key(i).match(/^student/)) {
        keys.push(window.localStorage.key(i));
      }
    }

    keys.forEach(function(key){
      window.localStorage.removeItem(key);
    });

  },

  getSkinName: function(skinId, variantId) {
    return FTWGLOBALS('skins').reduce(function(memo, row){ return (row.id == skinId) ? row.file + (variantId ? 'v' + variantId : '') : memo; }, 'appv1');
  },

  getSkinRank: function() {
    var skins = Registry.get('skins'),
      userActivity = Registry.get('userActivity'),
      skin = skins.get(this.get('skin_id') || 1),
      activity = userActivity.getOrAdd(0).toJSON();

    return skin.getRank(activity.typed - activity.errors);
  },

  getSkinNextRank: function() {
    var skins = Registry.get('skins'),
      userActivity = Registry.get('userActivity'),
      skin = skins.get(this.get('skin_id') || 1),
      activity = userActivity.getOrAdd(0).toJSON();

    return skin.getNextRank(activity.typed - activity.errors);
  },

  getSkin: function() {
    return Registry.get('skins').get(this.get('skin_id') || 1);
  },

  getSkinColors: function() {
    switch(this.get('skin_id')) {
    case 3: // princess
      return ['#f79697', '#F15052', '#EFD21B', '#00A098']; // pink, red, yellow, turquoise
    case 4: // forest
      return ['#00AAA4', '#F5CB5C', '#C19571', '#e7a3e9']; // teal, yellow, brown, pink
    case 5: // sea
      return ['#1A608E', '#6eb2e7', '#dfb353', '#9bcf6e']; // navy, light-blue, gold, green
    case 6: // pixel
      return ['#A1B845', '#D66E4A', '#9ab4da', '#bab7a6']; // green, red, light-blue, tan
    case 7: // space
      return ['#413C58', '#D7FF79', '#f66cad', '#BD2D71']; // purple, lime, pink, red
    case 8: // superhero
      return ['#2176ae', '#5fdad8', '#ebc435', '#a3243b']; // dark-blue, cyan, yellow, red
    case 9: // nitrotype
      return ['#D30006', '#7F808B', '#5BB40A', '#FCC316']; // red, grey, green, yellow
    default: // default, fox, base
      return ['#3295db', '#71b16b', '#ffce47', '#ce3e44']; // blue, green, yellow, red
    }
  },

  changeSkin: function(skinId, variantId = 1) {
    this.set({skin_id: skinId, variant_id: variantId});

    window.localStorage.setItem('student_skin', skinId);
    window.localStorage.setItem('student_variant', variantId);

    var styleSheet = $('#base-stylesheet'),
      fileParts = styleSheet.attr('href').split('/'),
      nameParts = fileParts.pop().split('.');

    nameParts[0] = this.getSkinName(skinId);
    styleSheet.attr('href', fileParts.join('/') + '/' + nameParts.join('.'));

    if(Registry.get('loggedIn')) {
      $.post('/apiv1/student/user/profile', {skin_id: skinId, variant_id: variantId});
    }
  },

  changeDailyGoal: function(dailyGoal, save = true) {
    this.setSettings({ daily_goal: dailyGoal })

    if(Registry.get('loggedIn') && save) {
      $.post('/apiv1/student/user/profile', { daily_goal: dailyGoal })
    }
  },

  getCurriculumLanguage: function() {
    const products = Registry.get('products'),
      productId = this.getSetting('product_id')

    return (products.get(productId)) ? products.get(productId).get('language') : FTWGLOBALS('defaultLanguage')
  },

  getCurriculumGrade: function() {
    const products = Registry.get('products'),
      productId = this.getSetting('product_id')

    return (products.get(productId)) ? products.get(productId).get('grade') : 0
  },

  toggleDictation: function() {
    const newDictation = this.getSetting('dictation') ? 0 : 1
    if(Registry.get('loggedIn')) {
      $.post('/apiv1/student/user/profile', { dictation: newDictation })
    }

    this.setSettings({ dictation: newDictation })
  },

  isPremium: function() {
    return this.get('licensed_at') > 0
  },

  resetTests: function() {
    return $.ajax({
      url: '/apiv1/student/user/reset-tests',
      method: 'post'
    })
  },

  hasTakenTest: function(type, duration) {
    const userTests = Registry.get('userTests').toJSON()

    return userTests.filter((row) => {
      if(type === 'timed') {
        return row.testType === type && row.seconds === duration
      } else {
        return row.testType === duration + '-' + type
      }
    }).length > 0
  },

  isAssessment: function() {
    return FTWGLOBALS('whitelabel') && this.get('licensed_at') === 0
  }
})
