import $ from 'jquery'
import Registry from '@registry'
import AdView from '../../global/views/ad'
import TypingView from './typing'
import { find } from 'lodash-es'
import { lesson_typing_inline_game } from '@templates/student'

export default TypingView.extend({
  template: lesson_typing_inline_game,

  allowInput: false,

  isSmallScreen: false,
  keyboardTopOffset: 0,

  gameWidth: 930,
  gameHeight: 523,

  initialize: function() {
    TypingView.prototype.initialize.apply(this, arguments);

    var hasAds = AdView.canShowAds(),
      contentHeight = $(window).innerHeight();

    // isSmallScreen will remove the white box around the game
    // ads are 100px
    this.isSmallScreen = (hasAds && contentHeight < 1024) || (!hasAds && contentHeight < 1124);
    if(this.isSmallScreen) {
      this.gameWidth += 40;

      if(this.keyboardView) { // Only set if the keyboard is visible
        this.keyboardView.hasShadow = true; // put a shadow on the keyboard so it looks better
        this.keyboardView.showSettings = false; // little "Keyboard Settings" link goes away
      }

      // if the screen is small, we push the keyboard up into the game area
      if(hasAds) {
        this.keyboardTopOffset = contentHeight >= 890 ? -65 : -130;
      } else {
        this.keyboardTopOffset = contentHeight > 690 ? -123 : -85;
      }
    }

    // figure out the game sizing for small heights
    if((hasAds && contentHeight < 800) || (!hasAds && contentHeight < 700)) {
      var baselineHeight = (hasAds) ? 800 : 700,
        ratio = 1 - ((baselineHeight - contentHeight) / baselineHeight);

      this.gameHeight *= ratio;
    }

    this.listenTo(this.user, 'settings_changed', (data) => {
      if(data.includes('error_sounds')) {
        this.toggleErrorMute()
      }
      if(data.includes('typing_sounds')) {
        this.toggleSFXMute()
      }
    });
  },

  render: function() {
    var userActivity = Registry.get('userActivity'),
      totalScreens = userActivity.getOrAdd(0).get('screens');

    // Get the game details for the inline game
    this.gameData = find(FTWGLOBALS('games'), { folder: this.screen.get('screen_type') });

    this.$el.append(
      this.template({
        ads: AdView.canShowAds() && totalScreens > 0,
        adsClass: AdView.getLeftMarginClass({ forceFixedMargin: this.showSidebarNav, totalScreens: totalScreens }),
        lesson: this.lesson.toJSON(),
        screen: this.screen.toJSON(),
        user: this.user.toJSON(),
        isSmallScreen: this.isSmallScreen,
        keyboardTopOffset: this.keyboardTopOffset,
        gameHeight: this.gameHeight,
        gameWidth: this.gameWidth
      })
    );

    this.downloadGameSource();

    return TypingView.prototype.render.apply(this, arguments);
  },

  downloadGameSource: function() {
    var s = document.createElement('script');
    let type = this.screen.get('screen_type')
    if (type === 'keyboard-jump') {
      type = `${type}-legacy`
    }

    s.addEventListener('load', this.runGame.bind(this));
    s.src = '/dist/student/extras/game_files/' + type + '/app.min.' + FTWGLOBALS('cacheId') + '.js';
    document.head.appendChild(s);
  },

  runGame: function() {
    // Get the game object stored in FTWGLOBALS
    var game = FTWGLOBALS(this.gameData.folder);

    // If the game did not properly load we flag the user as unable to see this screen type and reload the screen (this triggers a standard screen to load)
    if(!game) {
      this.user.set('_inlineIncompatible', true)
      window.location.safeReload()
    }
    
    var avatar = this.user.getSkinName(this.user.get('skin_id'), this.user.get('variant_id'));
    this.game = game({
      loader: { baseURL: '/dist/student/extras/game_files/keyboard-jump-legacy/' },
      parent: 'js-game',
      width: this.gameWidth,
      height: this.gameHeight
    },
    {
      gameType: 'inline',
      muteMusic: !(this.user.getSetting('typing_sounds') && this.user.getSetting('error_sounds')),
      avatar: avatar.substring(0, avatar.length - 2),
      environment: this.screen.get('content_setting'),
      muteSFX: !this.user.getSetting('typing_sounds'),
      muteErrorSFX: !this.user.getSetting('error_sounds'),
      content: this.screen.get('formatted_content'),
      language: this.user.getCurriculumLanguage(),
      // Listen for the end game
      onGameOver: function() { this.screen.trigger('complete'); }.bind(this),
      // Listen for when the user can type
      onAllowInput: function() {
        this.allowInput = true;
        if(this.keyboardView) {
          this.keyboardView.highlightKey(this.screen.charAt(0));
        }
      }.bind(this)
    });
  },

  toggleSFXMute: function() {
    if(this.game) {
      this.game.muteSFX(!this.user.getSetting('typing_sounds'));
    }
  },

  toggleErrorMute: function() {
    if(this.game) {
      this.game.muteErrorSFX(!this.user.getSetting('error_sounds'));
    }
  },

  handleKeyTyped: function() {
    TypingView.prototype.handleKeyTyped.apply(this, arguments);

    this.game.onKeyPressed(true);
  },

  handleErrorTyped: function(){
    TypingView.prototype.handleErrorTyped.apply(this, arguments);

    this.game.onKeyPressed(false);
  },

  handleKeyPressed: function(model) {
    TypingView.prototype.handleKeyPressed.apply(this, arguments);

    if(model.get('isError')) {
      this.game.onKeyPressed(false);
    }
  }
})
